'use client'

import { getOctoBotCloudAppUrl } from '@astrolab/back/lib/urls'
import { getLocalizedUrl } from '@astrolab/web/lib/locales'
import { useTranslations, useLocale } from 'next-intl'
import { Box, Button, Flex, Icon, Link, useColorModeValue } from '@chakra-ui/react'
import { MdLogin } from 'react-icons/md'
import { LocalizedLink } from '@astrolab/web/common/LocalizedLink/LocalizedLink'
import { defaultLocale } from '@astrolab/web/constants'

export const LayoutActionButtons = ({}: {}) => {
  const t = useTranslations('layout')
  const locale = useLocale()
  // force locale in app url as this is another domain, LocalizedLink is not handling it automatically
  const appUrl = getLocalizedUrl(
    getOctoBotCloudAppUrl() || '',
    locale,
    defaultLocale
  )

  const textColor = useColorModeValue('secondaryGray.900', 'white')
  return (
    <>
      <Flex direction="row" gap={2}>
        <LocalizedLink href={`${appUrl}/register`} noFollow aria-label={t('start-your-octobot')}>
          <Button
            variant="brand"
            display={{ base: 'none', md: 'flex' }}
            minW="75px"
            fontSize="sm"
            fontWeight="500"
            data-ph-capture-attribute-button-role="startYourHostingOctoBot"
          >
            {t('start-your-octobot')}
          </Button>
        </LocalizedLink>
        <LocalizedLink href={appUrl} noFollow aria-label={t('login')}>
          <Button
            bg={{ base: 'transparent', lg: 'inherit' }}
            borderColor="brand"
            fontSize="15px"
            fontWeight="200"
            color={textColor}
            variant={{ base: 'no-hover', lg: 'outline' }}
            px={{ base: 3, xl: 4 }}
            aria-label={t('login')}
          >
            <Icon
              h="18px"
              w={{ base: '18px', lg: 0 }}
              color={textColor}
              display={{ base: 'flex', lg: 'none' }}
              data-ph-capture-attribute-button-role="hostingLogin"
              as={MdLogin}
            />
            <Box as="span" display={{ base: 'none', lg: 'flex' }}>
              {t('login')}
            </Box>
          </Button>
        </LocalizedLink>
      </Flex>
    </>
  )
}
