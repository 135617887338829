import(/* webpackMode: "eager" */ "/home/runner1/actions-runner/_work/Astrolab/Astrolab/frontend/packages/astrolab-web/dist/common/Layout/HomeLayout.js");
;
import(/* webpackMode: "eager", webpackExports: ["RootLayout"] */ "/home/runner1/actions-runner/_work/Astrolab/Astrolab/frontend/packages/astrolab-web/dist/common/Layout/RootLayout.js");
;
import(/* webpackMode: "eager", webpackExports: ["LayoutActionButtons"] */ "/home/runner1/actions-runner/_work/Astrolab/Astrolab/frontend/web/landing/app/[locale]/(trading-bot)/LayoutActionButtons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/runner1/actions-runner/_work/Astrolab/Astrolab/frontend/web/landing/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner1/actions-runner/_work/Astrolab/Astrolab/node_modules/.pnpm/next-intl@3.19.4_next@14.2.13_@babel+core@7.25.2_@opentelemetry+api@1.9.0_react-dom@18.3.1_re_xf5offiza7wqau3xpgtqubq274/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager" */ "/home/runner1/actions-runner/_work/Astrolab/Astrolab/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"../../packages/astrolab-web/dist/common/Layout/index.js\",\"import\":\"DM_Sans\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"]}],\"variableName\":\"sourceCodeDMSans\"}");
